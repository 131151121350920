class Form {

  constructor() {
    this.setup();
    this.validation();
    this.sending();
  }

  setup() {
    const el = document.querySelector('#form-errors');
    const errors = JSON.parse(el.innerHTML);

    this.bouncerSettings = {
      messageAfterField: true,
      messages: {
        missingValue: {
          checkbox: errors.required,
          radio: errors.select,
          select: errors.select,
          default: errors.required
        },
        patternMismatch: {
          email: errors.email,
          default: errors.mismatch
        },
      },
      disableSubmit: true
    }
  }

  validation() {
    if (!document.querySelector('[data-bouncer]')) return;

    const bouncer = new Bouncer('[data-bouncer]', this.bouncerSettings);

    this.activateInputs();

    document.addEventListener('bouncerRemoveError', e => {
      var field = e.target;
      field.classList.add('valid');
    }, false);

    document.addEventListener('bouncerShowError', e => {
      var field = e.target;
      field.classList.remove('valid');
    }, false);
  }

  activateInputs() {
    const elems = document.querySelectorAll('.js-input');

    if (!elems.length) return;

    elems.forEach(el => {
      el.addEventListener('click', e => {
        const label_el = el.closest('.js-label');
        label_el.classList.add('is-active');
      });

      el.addEventListener('focus', e => {
        const label_el = el.closest('.js-label');
        label_el.classList.add('is-active');
      });
    });
  }

  sending() {
    document.addEventListener('bouncerFormValid', e => {
      const form = e.target;
      const type = form.dataset.type;

      const btn = form.querySelector('[type="submit"]');

      const url = `${form.action}wp-admin/admin-ajax.php`;

      const fd = new FormData(form);

      fd.append('action', 'mail');

      form.classList.add('is-process');
      btn.setAttribute('disabled', true);

      axios.post(url, fd).then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          form.classList.remove('is-process');
          btn.removeAttribute('disabled');
          form.reset();

          form.classList.add('is-success');

          setTimeout(() => {
            form.classList.remove('is-success');

            if (document.querySelector('.unimodal.is-active')) {
              Unimodal.close(document.querySelector('.unimodal.is-active').id);
            }
          }, 3000);

          if (res.data.data.url) {
            window.location.assign(res.data.data.url);
          }
        }
      });
    }, false);
  }
};

export default Form;

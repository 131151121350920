import Form from './modules/Form.js';
import Slider from './modules/Slider.js';

class App {
  constructor() {
    svg4everybody();

    this.toggler();
    this.mainHeight();
    this.unimodal();

    new Form();
    new Slider();
  }

  toggler() {
    const toggle = new Unitoggle({
      onOpen: tab => {
        // Top Menu
        if (tab.id == 'header') {
          this.blockScroll(true);
        }
      },
      onClose: tab => {
        this.blockScroll(false);
      }
    });
  }

  blockScroll(status = false) {
    if (!status) {
      document.documentElement.classList.remove('is-no-scroll');
      return;
    }

    document.documentElement.classList.add('is-no-scroll');
  }

  mainHeight() {
    const el = document.querySelector('#main');

    if (!el) return;

    el.style.height = window.innerHeight + 'px';
  }

  unimodal() {
    const modal = new Unimodal({
      scrollWindow: false,
      onOpen: ( modal, button ) => {
      },
      onClose: modal => {
      }
    });
  }
};

new App();

class Slider {
  constructor() {
    this.slPortfolio();
    this.slTeam();
    this.slCases();
    this.slCase();
  }

  slPortfolio() {
    if (window.innerWidth < 1024) return;

    const el = document.querySelector('#sl-portfolio');

    if (!el) return;

    const sl = new Swiper(el, {
      slidesPerView: 'auto',
      slideNextClass: 'is-next',
      slidePrevClass: 'is-prev',
      slideActiveClass: 'is-active',
      navigation: {
        nextEl: el.querySelector('.js-next'),
        prevEl: el.querySelector('.js-prev'),
        disabledClass: 'is-disabled',
      },
      on: {
        reachEnd: sw => {
          let offset = (window.innerWidth > 767) ? 90 : 40;

          setTimeout(() => {
            sw.setTranslate(sw.translate - offset);
            sw.slides[sw.slides.length - 1].classList.add('is-end');
          }, 300);
        },
        fromEdge: sw => {
          sw.slides[sw.slides.length - 1].classList.remove('is-end');
        }
      }
    });
  }

  slTeam() {
    const el = document.querySelector('#sl-team');

    if (!el) return;

    if (window.innerWidth < 1280 && window.innerWidth > 767) {
      const slides = el.querySelectorAll('.swiper-slide');
      let h = window.innerHeight / 2;

      if (slides.length > 4) {
        h = window.innerHeight / 2 + h / 2;
      }

      if (window.innerWidth < 1024) {
        h = window.innerHeight / 2;

        if (slides.length > 3) {
          h = window.innerHeight - (window.innerHeight / 4);
        }
      }

      slides.forEach(el => {
        el.style.height = h + 'px';
      });

      return;
    }

    const sl = new Swiper(el, {
      direction: (window.innerWidth < 768) ? 'vertical' : 'horizontal',
      slidesPerView: 'auto',
      slideNextClass: 'is-next',
      slidePrevClass: 'is-prev',
      slideActiveClass: 'is-active',
      navigation: {
        nextEl: el.querySelector('.js-next'),
        prevEl: el.querySelector('.js-prev'),
        lockClass: 'is-lock',
        disabledClass: 'is-disabled',
      },
      on: {
        reachEnd: sw => {
          let offset = (window.innerWidth > 767) ? 90 : 40;

          setTimeout(() => {
            sw.setTranslate(sw.translate - offset);
            sw.slides[sw.slides.length - 1].classList.add('is-end');
          }, 300);
        },
        fromEdge: sw => {
          sw.slides[sw.slides.length - 1].classList.remove('is-end');
        }
      }
    });
  }

  slCases() {
    const el = document.querySelector('#sl-cases');

    if (!el) return;

    if (window.innerWidth < 1280 && window.innerWidth > 767) {
      const slides = el.querySelectorAll('.swiper-slide');
      let h = window.innerHeight / 2;

      if (slides.length > 4) {
        h = window.innerHeight / 2 + h / 2;
      }

      if (window.innerWidth < 1024) {
        h = window.innerHeight / 2;

        if (slides.length > 3) {
          h = window.innerHeight - (window.innerHeight / 4);
        }
      }

      slides.forEach(el => {
        el.style.height = h + 'px';
      });

      return;
    }

    const sl = new Swiper(el, {
      direction: (window.innerWidth < 768) ? 'vertical' : 'horizontal',
      slidesPerView: 'auto',
      slideNextClass: 'is-next',
      slidePrevClass: 'is-prev',
      slideActiveClass: 'is-active',
      navigation: {
        nextEl: el.querySelector('.js-next'),
        prevEl: el.querySelector('.js-prev'),
        lockClass: 'is-lock',
        disabledClass: 'is-disabled',
      },
      keyboard: {
        enabled: true
      },
      on: {
        init: sw => {
          setTimeout(() => {
            sw.update();
          }, 300);
        },
        reachEnd: sw => {
          let offset = (window.innerWidth > 767) ? 90 : 40;

          setTimeout(() => {
            sw.setTranslate(sw.translate - offset);
            sw.slides[sw.slides.length - 1].classList.add('is-end');
          }, 300);
        },
        fromEdge: sw => {
          sw.slides[sw.slides.length - 1].classList.remove('is-end');
        }
      }
    });
  }

  slCase() {
    const el = document.querySelector('#sl-case');

    if (!el) return;

    const sl = new Swiper(el, {
      slidesPerView: 'auto',
      slideNextClass: 'is-next',
      slidePrevClass: 'is-prev',
      slideActiveClass: 'is-active',
      navigation: {
        nextEl: el.querySelector('.js-next'),
        prevEl: el.querySelector('.js-prev'),
        disabledClass: 'is-disabled',
      },
      keyboard: {
        enabled: true
      },
    });
  }
}

export default Slider;
